import dynamic from "next/dynamic";
import Head from "next/head";
import "swagger-ui-react/swagger-ui.css";

const SwaggerUI = dynamic<{ url: string }>(import("swagger-ui-react"), {
  ssr: false,
});

export default function Index() {
  return (
    <div>
      <Head>
        <title>Kanpla Open API Spec</title>
      </Head>
      <SwaggerUI url="/openapispec.json" />
    </div>
  );
}
